import axios from 'axios'
import router from '../router/routes'

// axios 配置
axios.defaults.timeout = 5000
// axios.defaults.baseURL = 'http://54.82.224.31:8000'
// axios.defaults.baseURL = 'http://192.168.0.12:8000'
axios.defaults.baseURL = 'https://berth-monitoring.com/api'

// http request 拦截器
axios.interceptors.request.use(
  config => {
    let token = localStorage.getItem("token")
    if (token) {
      config.headers.Authorization = `JWT ` + token
    }
    return config
  },
  err => {
    return Promise.reject(err)
  },
)

// http response 拦截器
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 401 清除token信息并跳转到登录页面
        //   store.commit(types.LOGOUT)
          
          // 只有在当前路由不是登录页面才跳转
          router.currentRoute.path !== 'login' &&
            router.replace({
              path: 'login',
              query: { redirect: router.currentRoute.path },
            })
          break;
        case 404:
          router.push({
            name: 'errorPage',
            params: {
              code: 404,
            }
          })
          break;
        case 500:
          router.push({
            name: 'errorPage',
            params: {
              code: 500,
            }
          })
          break;
      }
    }
    // console.log(JSON.stringify(error));//console : Error: Request failed with status code 402
    return Promise.reject(error.response.data)
  },
)

export default axios
