<style>
.active-test {
  color: #0a0a0a !important;
  background-color: rgb(112, 191, 255) !important;
}
</style>

<template>
  <v-app>
    <v-card color="grey lighten-4" flat height="100px" tile>
      <v-toolbar color="elevation-0" dark dense>
        <v-icon @click="toMainMenu">mdi-home</v-icon>
        <v-spacer></v-spacer>

        <v-toolbar-title style="align-center"
          >管理室モニター（バース使用状況履歴）
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-icon>mdi-logout</v-icon>
      </v-toolbar>
      <v-tabs grow background-color="indigo" dark active-class="active-test" v-model="active_tab">
        <v-tab style="font-size: 18px" @click="toSelectionMap">
          バース使用状況
        </v-tab>
        <v-tab style="font-size: 18px" @click="toUsageHistory">
          バース使用状況履歴
        </v-tab>
        <v-tab style="font-size: 18px" @click="toStorageHistory">
          敷地入退場履歴
        </v-tab>
        <v-tab style="font-size: 18px" @click="toTenantList">
          テナント管理
        </v-tab>
      </v-tabs>
    </v-card>

    <v-main style="background: #EEEEEE">
      <v-row class="mt-0" dense>
        <v-col lg-6>
          <v-container>
            <v-btn
              style="width: 120px; height: 40px; font-size: 16px"
              color="primary"
            >
              <v-icon left>mdi-warehouse</v-icon>
              倉庫番号</v-btn
            >
          </v-container>
        </v-col>
        <v-col lg-6 class="text-right">
          <v-container>
            <v-btn
              style="width: 120px; height: 40px; font-size: 16px"
              color="success"
            >
              <v-icon left>mdi-printer</v-icon>
              CSV出力</v-btn
            >
            <v-btn
              style="width: 120px; height: 40px; font-size: 16px"
              class="ml-3"
              color="blue-grey lighten-4"
              @click="toUsageHistory"
            >
              <v-icon left>mdi-backspace</v-icon>
              戻る</v-btn
            >
          </v-container>
        </v-col>
      </v-row>
      <template>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="items"
          item-key="name"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="elevation-1"
          :no-data-text="'データがありません'"
        >
        </v-data-table>
      </template>
    </v-main>
    <v-footer padless>
      <v-col class="text-center" cols="12">
        <v-pagination v-model="page" :length="pageCount"></v-pagination>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "UsageHistory_1",

  data: () => ({
    page: 1,
    pageCount: 2,
    itemsPerPage: 10,
    active_tab: 1,
    selected: [],
    headers: [
      { text: "倉庫番号", value: "storageNumber", width: "16%" },
      { text: "駐車No", value: "parkingNo", width: "16%"  },
      { text: "テナント", value: "tenant", width: "16%"  },
      { text: "入庫日時", value: "carInDate", width: "18%"  },
      { text: "出庫日時", value: "carOutDate", width: "18%"  },
      { text: "使用時間", value: "usageTime", width: "16%"  },
    ],
    items: [
      {
        name: "Item1",
        carInDate: "2022/4/19(火) 10:10",
        carOutDate: "2022/4/19(火) 10:40",
        usageTime: "0:30",
        tenant: "ABC株式会社",
        storageNumber: "A101",
        parkingNo: "2",
        shippingCompany: "ABC運送",
        number: "品川 100 あ 1234",
        carInOut: "入庫",
        carInfo: "中型",
        loadingInfo: "パレット",
      },
      {
        name: "Item2",
        carInDate: "2022/4/19(火) 10:10",
        carOutDate: "2022/4/19(火) 10:40",
        usageTime: "0:30",
        tenant: "ABC株式会社",
        storageNumber: "A101",
        parkingNo: "2",
        shippingCompany: "ABC運送",
        number: "品川 100 あ 1234",
        carInOut: "入庫",
        carInfo: "中型",
        loadingInfo: "パレット",
      },
      {
        name: "Item3",
        carInDate: "2022/4/19(火) 10:10",
        carOutDate: "2022/4/19(火) 10:40",
        usageTime: "0:30",
        tenant: "ABC株式会社",
        storageNumber: "A101",
        parkingNo: "2",
        shippingCompany: "ABC運送",
        number: "品川 100 あ 1234",
        carInOut: "入庫",
        carInfo: "中型",
        loadingInfo: "パレット",
      },
      {
        name: "Item4",
        carInDate: "2022/4/19(火) 10:10",
        carOutDate: "2022/4/19(火) 10:40",
        usageTime: "0:30",
        tenant: "ABC株式会社",
        storageNumber: "A101",
        parkingNo: "2",
        shippingCompany: "ABC運送",
        number: "品川 100 あ 1234",
        carInOut: "入庫",
        carInfo: "中型",
        loadingInfo: "パレット",
      },
      {
        name: "Item5",
        carInDate: "2022/4/19(火) 10:10",
        carOutDate: "2022/4/19(火) 10:40",
        usageTime: "0:30",
        tenant: "ABC株式会社",
        storageNumber: "A101",
        parkingNo: "2",
        shippingCompany: "ABC運送",
        number: "品川 100 あ 1234",
        carInOut: "入庫",
        carInfo: "中型",
        loadingInfo: "パレット",
      },
      {
        name: "Item6",
        carInDate: "2022/4/19(火) 10:10",
        carOutDate: "2022/4/19(火) 10:40",
        usageTime: "0:30",
        tenant: "ABC株式会社",
        storageNumber: "A101",
        parkingNo: "2",
        shippingCompany: "ABC運送",
        number: "品川 100 あ 1234",
        carInOut: "入庫",
        carInfo: "中型",
        loadingInfo: "パレット",
      },
      {
        name: "Item7",
        carInDate: "2022/4/19(火) 10:10",
        carOutDate: "2022/4/19(火) 10:40",
        usageTime: "0:30",
        tenant: "ABC株式会社",
        storageNumber: "A101",
        parkingNo: "2",
        shippingCompany: "ABC運送",
        number: "品川 100 あ 1234",
        carInOut: "入庫",
        carInfo: "中型",
        loadingInfo: "パレット",
      },
      {
        name: "Item8",
        carInDate: "2022/4/19(火) 10:10",
        carOutDate: "2022/4/19(火) 10:40",
        usageTime: "0:30",
        tenant: "ABC株式会社",
        storageNumber: "A101",
        parkingNo: "2",
        shippingCompany: "ABC運送",
        number: "品川 100 あ 1234",
        carInOut: "入庫",
        carInfo: "中型",
        loadingInfo: "パレット",
      },
      {
        name: "Item9",
        carInDate: "2022/4/19(火) 10:10",
        carOutDate: "2022/4/19(火) 10:40",
        usageTime: "0:30",
        tenant: "ABC株式会社",
        storageNumber: "A101",
        parkingNo: "2",
        shippingCompany: "ABC運送",
        number: "品川 100 あ 1234",
        carInOut: "入庫",
        carInfo: "中型",
        loadingInfo: "パレット",
      },
      {
        name: "Item10",
        carInDate: "2022/4/19(火) 10:10",
        carOutDate: "2022/4/19(火) 10:40",
        usageTime: "0:30",
        tenant: "ABC株式会社",
        storageNumber: "A101",
        parkingNo: "2",
        shippingCompany: "ABC運送",
        number: "品川 100 あ 1234",
        carInOut: "入庫",
        carInfo: "中型",
        loadingInfo: "パレット",
      },
      {
        name: "Item11",
        carInDate: "2022/4/19(火) 10:10",
        carOutDate: "2022/4/19(火) 10:40",
        usageTime: "0:30",
        tenant: "ABC株式会社",
        storageNumber: "A101",
        parkingNo: "2",
        shippingCompany: "ABC運送",
        number: "品川 100 あ 1234",
        carInOut: "入庫",
        carInfo: "中型",
        loadingInfo: "パレット",
      },
    ],
  }),
  methods: {
    // 管理室モニター（メインメニュー）に遷移する
    toMainMenu: function () {
      this.$router.replace("/mainmenu");
    },
    // バース使用状況画面に遷移する
    toSelectionMap: function () {
      this.$router.replace("/selectionmap");
    },
    // バース使用状況履歴画面に遷移する
    toUsageHistory: function () {
      this.$router.replace("/usagehistory");
    },
    // 敷地入退場履歴画面に遷移する
    toStorageHistory: function () {
      this.$router.replace("/storagehistory");
    },
    // テナント管理画面に遷移する
    toTenantList: function () {
      this.$router.replace("/tenantlist");
    },
  },
};
</script>