import UsageHistory_1 from "@/views/pc/UsageHistory_1.vue";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    components: {
      default: () => import("@/views/pc/ManagementLogin"),
      pc: () => import("@/views/pc/ManagementLogin"),
      mobile: () => import("@/views/mobile/ManagementLogin"),
    },
  },
  {
    path: "/login",
    name: "login",
    components: {
      default: () => import("@/views/pc/ManagementLogin"),
      pc: () => import("@/views/pc/ManagementLogin"),
      mobile: () => import("@/views/mobile/ManagementLogin"),
    },
  },
  {
    path: "/usagehistory1",
    name: "usagehistory1",
    component: UsageHistory_1,
  },
  {
    path: "/mainmenutenant",
    name: "mainmenutenant",
    components: {
      default: () => import("@/views/pc/MainMenuTenant"),
      pc: () => import("@/views/pc/MainMenuTenant"),
      mobile: () => import("@/views/mobile/MainMenuTenant"),
    },
  },
  {
    path: "/selectionmaptenant",
    name: "selectionmaptenant",
    components: {
      default: () => import("@/views/pc/SelectionMapTenant"),
      pc: () => import("@/views/pc/SelectionMapTenant"),
      mobile: () => import("@/views/mobile/SelectionMapTenant"),
    },
  },
  // {
  //   path: "/selectionmaptenant_1",
  //   name: "selectionmaptenant_1",
  //   components: {
  //     default: () => import("@/views/mobile/SelectionMapTenant_1"),
  //     pc: () => import("@/views/pc/SelectionMapTenant_1"),
  //     mobile: () => import("@/views/mobile/SelectionMapTenant_1"),
  //   },
  // },
  {
    path: "/selectionmaptenant_2",
    name: "selectionmaptenant_2",
    components: {
      default: () => import("@/views/pc/SelectionMapTenant_2"),
      pc: () => import("@/views/pc/SelectionMapTenant_2"),
      mobile: () => import("@/views/mobile/SelectionMapTenant_2"),
    },
  },
  // {
  //   path: "/selectionmaptenant_3",
  //   name: "selectionmaptenant_3",
  //   components: {
  //     default: () => import("@/views/mobile/SelectionMapTenant_3"),
  //     pc: () => import("@/views/pc/SelectionMapTenant_3"),
  //     mobile: () => import("@/views/mobile/SelectionMapTenant_3"),
  //   },
  // },
  // {
  //   path: "/selectionmaptenant_4",
  //   name: "selectionmaptenant_4",
  //   components: {
  //     default: () => import("@/views/mobile/SelectionMapTenant_4"),
  //     mobile: () => import("@/views/mobile/SelectionMapTenant_4"),
  //   },
  // },
  {
    path: "/selectionmaptenant_waitingroom",
    name: "selectionmaptenant_waitingroom",
    components: {
      default: () => import("@/views/mobile/SelectionMapTenant_waitingRoom"),
      mobile: () => import("@/views/mobile/SelectionMapTenant_waitingRoom"),
    },
  },
  {
    path: "/usagehistorytenant",
    name: "usagehistorytenant",
    components: {
      default: () => import("@/views/pc/UsageHistoryTenant"),
      pc: () => import("@/views/pc/UsageHistoryTenant"),
      mobile: () => import("@/views/mobile/UsageHistoryTenant"),
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((r) => r.meta.requireAuth)) {
    let token = localStorage.getItem("token");
    if (token) {
      next();
    } else {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    }
  } else {
    next();
  }
});

export default router;
