import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import '@mdi/font/css/materialdesignicons.css'
import router from "./router/routes";
import axios from './utils/axios'

Vue.config.productionTip = false

// 将axios挂载到prototype上，在组件中可以直接使用this.axios访问
Vue.prototype.axios = axios;

new Vue({
  vuetify,
  router,
  icons: {
    iconfont: 'mdi',
  },
  render: h => h(App)
}).$mount('#app')
