<template>
  <v-app>
    <router-view v-if="showPage === 1" name="mobile"></router-view>
    <router-view v-if="showPage === 2" name="pc"></router-view>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data() {
    return {
      showPage: "",
    };
  },
  created() {
    if (this._isMobile()) {
      this.showPage = 1;
    } else {
      this.showPage = 2;
    }
    console.log(this.showPage);
  },
  methods: {
    _isMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
